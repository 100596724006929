import { Col, Row } from "antd";
import { Button } from "../../../components/Form/Button/styled";
import Typography from "../../../components/Typography";
import Spacer from "../../../components/Content/Spacer";
import { Container, Header } from "./styled";
import useMobile from "../../../hooks/useMobile";
import Image from "next/image";
import Link from "next/link";
const HEADER_FONT_SIZE = 2.5;
const HEADER_FONT_SIZE_MOBILE = 2.25;

export default function Banner() {
  const isMobile = useMobile();
  const TEXT_ALIGN = isMobile ? "center" : "left";

  return (
    <Header>
      <Image
        src={!isMobile ? "/images/kitchen-home/banner.jpg" : "/images/kitchen-home/banner-mobile.jpg"}
        layout="fill"
        priority
        objectFit="cover"
        quality={50}
        className={"banner-image"}
      />
      <Row>
        <Col xs={24} md={14}>
          <Container>
            <Typography
              color="white"
              // fontWeight="bold"
              fontSize={isMobile ? `${HEADER_FONT_SIZE_MOBILE}rem` : `${HEADER_FONT_SIZE}rem`}
              textAlign={TEXT_ALIGN}
              fontFamily="Lora Bold"
              style={{ letterSpacing: "1px" }}
              className="banner-title"
              margin={0}
            >
              Elevate Your Home with Classy.
            </Typography>
            <Spacer size="spacing_xxl_2" />
            <Typography
              color="white"
              // fontWeight="bold"
              fontSize={isMobile ? `${HEADER_FONT_SIZE_MOBILE / 1.3}rem` : `${HEADER_FONT_SIZE / 1.2}rem`}
              textAlign={TEXT_ALIGN}
              fontFamily="Roboto Medium"
              margin={0}
            >
              Our {" "}
              <a
                href="/closets"
                className="custom-closet"
              >
                custom kitchen
              </a>
              solutions
            </Typography>
            <Typography
              color="white"
              // fontWeight="bold"
              fontSize={isMobile ? `${HEADER_FONT_SIZE_MOBILE / 1.3}rem` : `${HEADER_FONT_SIZE / 1.2}rem`}
              textAlign={TEXT_ALIGN}
              fontFamily="Roboto Medium"
              margin={0}
            >
              will enhance your family's
            </Typography>
            <Typography
              color="white"
              // fontWeight="bold"
              fontSize={isMobile ? `${HEADER_FONT_SIZE_MOBILE / 1.3}rem` : `${HEADER_FONT_SIZE / 1.2}rem`}
              textAlign={TEXT_ALIGN}
              fontFamily="Roboto Medium"
              margin={0}
            >
              daily routines and
            </Typography>
            <Typography
              color="white"
              // fontWeight="bold"
              fontSize={isMobile ? `${HEADER_FONT_SIZE_MOBILE / 1.3}rem` : `${HEADER_FONT_SIZE / 1.2}rem`}
              textAlign={TEXT_ALIGN}
              fontFamily="Roboto Medium"
            >
              culinary adventures.
            </Typography>

            {isMobile && <Spacer size="spacing_xxl_2" />}
          </Container>
        </Col>
      </Row>
    </Header>
  );
}
