import styled from "styled-components";

export const Header = styled.div`
  position: relative;
  width: 100%;
`;
export const Container = styled.div`
  max-width: 100%;
  /* margin: auto; */
  margin-left: 5%;
  padding: 55px 30px 100px 30px;

  .custom-closet {
    background-color: rgb(118,39,56, .9);
    color: white;
    padding: 3px 10px;
    font-family: "Roboto Medium";
    font-style: italic;
    &:hover, &:focus {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @media only screen and (min-width: 1200px) {
    min-height: 325px;
  }

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    min-height: 375px;
    padding: 40px 15px 15px 30px;
    margin: auto;

    button {
      width: 100% !important;
    }
  }
`;
